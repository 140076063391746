<template>
  <div>
    <h1>{{ $t("error.title401") }}</h1>
    <p>{{ $t("error.body401") }}</p>
  </div>
</template>

<script>
export default {
  name: "Component401",
};
</script>

<style scoped></style>
